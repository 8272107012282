<template>
  <b-card class="row-height">
    <b-row cols="12">
      <b-col class="mb-2 col-md-12">
        <h2
          class="text-blue font-weight-bold mt-1"
          v-html="$t('documents')"
        ></h2>
      </b-col>
      <br />

      <b-table
        striped
        responsive
        hover
        class="position-relative"
        :items="array"
        :fields="fields"
      >
        <template #cell(type)="data">

          <span>{{ $t(data.item.type) }} </span>
        </template>
        <template #cell(title)="data">
          {{ data.item.name }}
        </template>
        <template #cell(custom)="data">
          <b-button
            class="mr-1 mobile"
            @click="downloadInvoice(data.item.type, data.item.name)"
            variant="outline-success "
            size="sm"
          >
            <FeatherIcon icon="DownloadIcon"></FeatherIcon>
          </b-button>
          <b-button
            class="mr-1 web"
            @click="readPdf(data.item.type, data.item.name)"
            variant="outline-success"
            size="sm"
          >
            <FeatherIcon icon="BookOpenIcon"></FeatherIcon>
          </b-button>
        </template>
      </b-table>
    </b-row>
  </b-card>
</template>
<script>
import fileDownload from "js-file-download";
import Table from "../Common/Table2.vue";
export default {
  components: { Table },
  data() {
    return {
      documents: null,
      array: [],
      loader: false,
      url: `/buyer/admin/show/${this.$route.params.id}`,
      passport_back: "",
      passport_front: "",
      selfie: "",
      utility_bill: "",
      certificate_of_incorporation: null,
      articles_of_association: null,
      extract_company_registry: null,
      proof_of_business: null,
      shareholder_registry: null,
      showPdfViwer: false,
      type: "",
      base64: "",
      source: null,
      src: "",
      embedType: "",
      loadPdf: false,
      fields1: [
        {
          label: this.$t("title"),
          align: "start",
          key: "title",
          sortable: false,
        },
        { label: "", customSlot: true, key: "custom", class: "text-right" },
      ],
      fields: [
        {
          label: this.$t("type"),
          align: "start",
          key: "type",
          sortable: false,
        },
        {
          label: this.$t("title"),
          align: "start",
          key: "title",
          sortable: false,
        },
        { label: "", customSlot: true, key: "custom", class: "text-right" },
      ],
    };
  },
  props: ["makeCall"],
  created() {
    // this.getUser();
  },
  watch: {
    makeCall(newValue, oldValue) {
      if (newValue !== oldValue && this.makeCall) {
        this.loader = true;
        this.getUser();
        this.loader = false;
        this.showPdfViwer = false;
      }
    },
  },
  methods: {
    getUser() {
      this.$http
        .post(`/buyer/admin/show/${this.$route.params.id}`)
        .then((res) => {
          this.array = [];
          console.log('this.documentssss',res.data.value)
          if (
            res.data &&
            res.data.value &&
            res.data.value.identification_documentsBoD
          ) {
            this.documents = res.data.value.identification_documentsBoD;
            console.log('this.documents',res.data.value.identification_documentsBoD)
            if (res.data.value.identification_documentsBoD) {
              let parsed = {
                pass_back: this.documents.passport_back
                  ? this.documents.passport_back.split("/")
                  : null,
                pass_front: this.documents.passport_front
                  ? this.documents.passport_front.split("/")
                  : null,
                  certificate_of_incorporation: this.documents.certificate_of_incorporation
                  ? this.documents.certificate_of_incorporation.split("/")
                  : null,
                  articles_of_association: this.documents.articles_of_association
                  ? this.documents.articles_of_association.split("/")
                  : null,
                  extract_company_registry: this.documents.extract_company_registry
                  ? this.documents.extract_company_registry.split("/")
                  : null,
                  proof_of_business: this.documents.proof_of_business
                  ? this.documents.proof_of_business.split("/")
                  : null,
                  shareholder_registry: this.documents.shareholder_registry
                  ? this.documents.shareholder_registry.split("/")
                  : null,
              };

              this.passport_back = parsed.pass_back
                ? parsed.pass_back[parsed.pass_back.length - 1]
                : null;
              if (this.passport_back && this.passport_back != null) {
                this.array.push({
                  type: "passport_back",
                  name: this.passport_back,
                });
              }
              this.passport_front = parsed.pass_front
                ? parsed.pass_front[parsed.pass_front.length - 1]
                : null;
              if (this.passport_front && this.passport_front != null) {
                this.array.push({
                  type: "passport_front",
                  name: this.passport_front,
                });
              }
              this.certificate_of_incorporation = parsed.certificate_of_incorporation
                ? parsed.certificate_of_incorporation[parsed.certificate_of_incorporation.length - 1]
                : null;
              if (this.certificate_of_incorporation) {
                this.array.push({ type: "certificate_of_incorporation", name: this.certificate_of_incorporation });
              }
              this.articles_of_association = parsed.articles_of_association
                ? parsed.articles_of_association[parsed.articles_of_association.length - 1]
                : null;
              if (this.articles_of_association) {
                this.array.push({
                  type: "articles_of_association",
                  name: this.articles_of_association,
                });
              }
              this.extract_company_registry = parsed.extract_company_registry
                ? parsed.extract_company_registry[parsed.extract_company_registry.length - 1]
                : null;
              if (this.extract_company_registry) {
                this.array.push({
                  type: "extract_company_registry",
                  name: this.extract_company_registry,
                });
              }
              this.proof_of_business = parsed.proof_of_business
                ? parsed.proof_of_business[parsed.proof_of_business.length - 1]
                : null;
              if (this.proof_of_business) {
                this.array.push({
                  type: "proof_of_business",
                  name: this.proof_of_business,
                });
              }
              this.shareholder_registry = parsed.shareholder_registry
                ? parsed.shareholder_registry[parsed.shareholder_registry.length - 1]
                : null;
              if (this.shareholder_registry) {
                this.array.push({
                  type: "shareholder_registry",
                  name: this.shareholder_registry,
                });
              }

            }
          } else {
            this.array = [];
          }
        });
    },
    downloadInvoice(item, name) {
      this.$http
        .post(
          "/buyer/admin/download/" + this.$route.params.id,
          { type: item },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          let parsed = response.data.type.split("/");

          fileDownload(response.data, name);
        })
        .catch((error) => {});
    },
    readPdf(item, name) {
      this.showPdfViwer = true;
      this.loadPdf = true;
      this.type = "";
      this.base64 = "";
      this.src = "";
      this.embedType = "";

      this.$http
        .post(
          "/buyer/admin/read/" + this.$route.params.id,
          { type: item },
          {
            responseType: "json",
          }
        )
        .then((response) => {
          this.base64 = response.data.value.b64;
          this.type = response.data.value.ext;
          this.$store.commit("app/SET_FILE_TYPE", this.type);
          this.$store.commit("app/SET_FILE_BASE64", this.base64);

          window.open("/read-files", "_blank");
        })
        .catch((error) => {});
    },
  },
};
</script>