<template>
    <b-card> 
      <h4 class="light-blue">{{ $t("upload_bod_documents") }}</h4>
      <br>
   
      <b-card-text class="mt-2" v-if="!showLoader">
      <div>
        <div v-for="(doc, index) in identificationDocs" :key="index">
          <div class="mandatory-documents-passport-front mt-1">
          <b-row>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <h6 class="mandatory-documents-label">
                <span>{{ $t("passport_id_front") }}</span>
              </h6>
            </div>
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12"> 
              <div class="mb-1 font-weight-bolder">
                {{ $t("passport_front_text") }}
              </div>
              <div class="mb-1" v-if="doc.passportFrontFile != null">
                {{ doc.passportFrontFile }}
                <feather-icon
                  v-if="!hideButton"
                  @click="removeFile('passport_front', doc.passportFrontFile, index)"
                  class="pointer text-danger"
                  icon="Trash2Icon"
                  size="18"
                />
              </div>

              <div v-if="doc.passportFrontFile == null">
                <b-form-file
                  style="display: none"
                  @change="uploadFile2($event, doc.passportFrontFile, 'passport_front',index)"
                  v-model.trim="doc.passport_front"
                  :placeholder="$t('upload_a_file_or_drag_files_here')"
                  drop-placeholder="Drop file here..."
                  :ref="'passport_front'+index"
                  :id="'passport_front'+index"
                ></b-form-file>

                <b-row class="col-lg-6 col-md-12 mr-0 pr-0">
                  <b-alert show variant="info" class="col-md-12">
                    <b-row class="col-md-12"> 
                      <b-col
                        class="col-md-12 mt-2 mb-2 text-center pointer"
                        @click="triggerUploadFile2('passport_front'+index)"
                      >
                        <div>
                          <feather-icon icon="UploadIcon" size="25" />
                          <span></span>
                          {{ $t("upload_a_file_or_drag_files_here") }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-alert>
                </b-row>
              </div>
              <div v-else>
                <span class="uploaded-text"
                  >{{ $t("uploaded") }}
                  <feather-icon icon="CheckIcon"></feather-icon>
                </span>
                
              </div>
            </div>
          </b-row>
        </div>
        <div class="mandatory-documents-passport-back">
          <b-row>
            <div class="col-xl-4 col-lg-4 col-md-12">
              <h6 class="mandatory-documents-label">
                <span>{{ $t("passport_id_back") }}</span>
              </h6>
            </div>

            <div class="col-xl-8 col-lg-8 col-md-12">
              <div class="mb-1 font-weight-bolder">
                {{ $t("passport_back_text") }}
              </div>
              <div class="mb-1" v-if="doc.passportBackFile != null">
                {{ doc.passportBackFile }}
                <feather-icon
                  @click="removeFile('passport_back', doc.passportBackFile, index)"
                  class="pointer text-danger"
                  v-if="!hideButton"
                  icon="Trash2Icon"
                  size="18"
                />
              </div>
              <div v-if="doc.passportBackFile == null">
                <b-form-file
                  style="display: none"
                  @change="uploadFile2($event, doc.passportBackFile, 'passport_back',index)"
                  v-model.trim="doc.passport_back"
                  :placeholder="$t('upload_a_file_or_drag_files_here')"
                  drop-placeholder="Drop file here..."
                  :ref="'passport_back'+index"
                  :id="'passport_back'+index"
                ></b-form-file>

                <b-row class="col-md-6 mr-0 pr-0">
                  <b-alert show variant="info" class="col-md-12">
                    <b-row class="col-md-12">
                      <b-col
                        class="col-md-12 mt-2 mb-2 text-center pointer"
                        @click="triggerUploadFile2('passport_back'+index)"
                      >
                        <div>
                          <feather-icon icon="UploadIcon" size="25" />
                          <span></span>
                          {{ $t("upload_a_file_or_drag_files_here") }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-alert>
                </b-row>
                <br />
                <b-form-group id="confirmation_checkbox">
                  <validation-provider
                    #default="{ errors }"
                    name="passport_back_included_in_passport_front_file"
                  >
                    <b-form-checkbox
                      :id="'checkbox-'+index"
                      v-model.trim="doc.file_passport_back_included"
                      name="passport_back_included_in_passport_front_file"
                      value="true"
                      unchecked-value="false"
                      @click="alreadyIncludedInFrontFile(index)"
                    >
                      {{ $t("already_included_in_front_file") }}
                    </b-form-checkbox>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div v-else>
                <span class="uploaded-text"
                  >{{ $t("uploaded") }}
                  <feather-icon icon="CheckIcon"></feather-icon>
                </span>
              </div>
            </div>
          </b-row>
        </div> 
      </div>
        
      <div class="mb-1">             
        <b-button @click="addAnotherBod()" size="sm" variant="outline-primary" class="ml-1">
          {{ $t('add_new') }} <feather-icon icon="PlusIcon"></feather-icon>
        </b-button>
      </div>
      <b-card style="background-color: rgb(230, 224, 224);">
        <h5 class="light-blue mb-1">{{ $t("please_download_bo_pdf") }}</h5>
        <div> 
          <div class="justify-center row text-center col-12"> 
            <b-button @click="openPdf()"> <feather-icon icon="DownloadIcon" class="mr-1" />{{ $t("download") }}</b-button>
          </div>  
        </div>
      </b-card>
    
        
       <br>
       <h4 class="light-blue mt-2">{{ $t("please_upload_finma_docs") }}</h4>
 <div>
    <div class="mandatory-documents-passport-back">
      <b-row>
        <div class="col-xl-4 col-lg-4 col-md-12">
          <h6 class="mandatory-documents-label">
            <span>{{ $t("certificate_of_incorporation") }}</span>
          </h6>
        </div>

        <div class="col-xl-8 col-lg-8 col-md-12">
          <div class="mb-1 font-weight-bolder">
            {{ $t("certificate_of_incorporation_text") }}
          </div>
          <div class="mb-1" v-if="certificate_of_incorporationFile != null">
            {{ certificate_of_incorporationFile }}
            <feather-icon
              @click="removeFile('certificate_of_incorporation', certificate_of_incorporationFile)"
              class="pointer text-danger"
              v-if="!hideButton"
              icon="Trash2Icon"
              size="18"
            />
          </div>
          <div v-if="certificate_of_incorporationFile == null">
            <b-form-file
              style="display: none"
              @change="uploadFile($event, certificate_of_incorporation, 'certificate_of_incorporation')"
              v-model.trim="certificate_of_incorporation"
              :placeholder="$t('upload_a_file_or_drag_files_here')"
              drop-placeholder="Drop file here..."
              ref="certificate_of_incorporation"
            ></b-form-file>

            <b-row class="col-md-6 mr-0 pr-0">
              <b-alert show variant="info" class="col-md-12">
                <b-row class="col-md-12">
                  <b-col
                    class="col-md-12 mt-2 mb-2 text-center pointer"
                    @click="triggerUploadFile('certificate_of_incorporation')"
                  >
                    <div>
                      <feather-icon icon="UploadIcon" size="25" />
                      <span></span>
                      {{ $t("upload_a_file_or_drag_files_here") }}
                    </div>
                  </b-col>
                </b-row>
              </b-alert>
            </b-row>
            <br />
            
          </div>
          <div v-else>
            <span class="uploaded-text"
              >{{ $t("uploaded") }}
              <feather-icon icon="CheckIcon"></feather-icon>
            </span>
          </div>
        </div>
      </b-row>
    </div>
    <div class="mandatory-documents-passport-back">
      <b-row>
        <div class="col-xl-4 col-lg-4 col-md-12">
          <h6 class="mandatory-documents-label">
            <span>{{ $t("articles_of_association") }}</span>
          </h6>
        </div>

        <div class="col-xl-8 col-lg-8 col-md-12">
          <div class="mb-1 font-weight-bolder">
            {{ $t("articles_of_association_text") }}
          </div>
          <div class="mb-1" v-if="articles_of_associationFile != null">
            {{ articles_of_associationFile }}
            <feather-icon
              @click="removeFile('articles_of_association', articles_of_associationFile)"
              class="pointer text-danger"
              v-if="!hideButton"
              icon="Trash2Icon"
              size="18"
            />
          </div>
          <div v-if="articles_of_associationFile == null">
            <b-form-file
              style="display: none"
              @change="uploadFile($event, articles_of_association, 'articles_of_association')"
              v-model.trim="articles_of_association"
              :placeholder="$t('upload_a_file_or_drag_files_here')"
              drop-placeholder="Drop file here..."
              ref="articles_of_association"
            ></b-form-file>

            <b-row class="col-md-6 mr-0 pr-0">
              <b-alert show variant="info" class="col-md-12">
                <b-row class="col-md-12">
                  <b-col
                    class="col-md-12 mt-2 mb-2 text-center pointer"
                    @click="triggerUploadFile('articles_of_association')"
                  >
                    <div>
                      <feather-icon icon="UploadIcon" size="25" />
                      <span></span>
                      {{ $t("upload_a_file_or_drag_files_here") }}
                    </div>
                  </b-col>
                </b-row>
              </b-alert>
            </b-row>
            <br />
            
          </div>
          <div v-else>
            <span class="uploaded-text"
              >{{ $t("uploaded") }}
              <feather-icon icon="CheckIcon"></feather-icon>
            </span>
          </div>
        </div>
      </b-row>
    </div>
    <div class="mandatory-documents-passport-back">
      <b-row>
        <div class="col-xl-4 col-lg-4 col-md-12">
          <h6 class="mandatory-documents-label">
            <span>{{ $t("extract_company_registry") }}</span>
          </h6>
        </div>

        <div class="col-xl-8 col-lg-8 col-md-12">
          <div class="mb-1 font-weight-bolder">
            {{ $t("extract_company_registry_text") }}
          </div>
          <div class="mb-1" v-if="extract_company_registryFile != null">
            {{ extract_company_registryFile }}
            <feather-icon
              @click="removeFile('extract_company_registry', extract_company_registryFile)"
              class="pointer text-danger"
              v-if="!hideButton"
              icon="Trash2Icon"
              size="18"
            />
          </div>
          <div v-if="extract_company_registryFile == null">
            <b-form-file
              style="display: none"
              @change="uploadFile($event, extract_company_registry, 'extract_company_registry')"
              v-model.trim="extract_company_registry"
              :placeholder="$t('upload_a_file_or_drag_files_here')"
              drop-placeholder="Drop file here..."
              ref="extract_company_registry"
            ></b-form-file>

            <b-row class="col-md-6 mr-0 pr-0">
              <b-alert show variant="info" class="col-md-12">
                <b-row class="col-md-12">
                  <b-col
                    class="col-md-12 mt-2 mb-2 text-center pointer"
                    @click="triggerUploadFile('extract_company_registry')"
                  >
                    <div>
                      <feather-icon icon="UploadIcon" size="25" />
                      <span></span>
                      {{ $t("upload_a_file_or_drag_files_here") }}
                    </div>
                  </b-col>
                </b-row>
              </b-alert>
            </b-row>
            <br />
            
          </div>
          <div v-else>
            <span class="uploaded-text"
              >{{ $t("uploaded") }}
              <feather-icon icon="CheckIcon"></feather-icon>
            </span>
          </div>
        </div>
      </b-row>
    </div>
    <div class="mandatory-documents-passport-back">
      <b-row>
        <div class="col-xl-4 col-lg-4 col-md-12">
          <h6 class="mandatory-documents-label">
            <span>{{ $t("proof_of_business") }}</span>
          </h6>
        </div>

        <div class="col-xl-8 col-lg-8 col-md-12">
          <div class="mb-1 font-weight-bolder">
            {{ $t("proof_of_business_text") }}
          </div>
          <div class="mb-1" v-if="proof_of_businessFile != null">
            {{ proof_of_businessFile }}
            <feather-icon
              @click="removeFile('proof_of_business', proof_of_businessFile)"
              class="pointer text-danger"
              v-if="!hideButton"
              icon="Trash2Icon"
              size="18"
            />
          </div>
          <div v-if="proof_of_businessFile == null">
            <b-form-file
              style="display: none"
              @change="uploadFile($event, proof_of_business, 'proof_of_business')"
              v-model.trim="proof_of_business"
              :placeholder="$t('upload_a_file_or_drag_files_here')"
              drop-placeholder="Drop file here..."
              ref="proof_of_business"
            ></b-form-file>

            <b-row class="col-md-6 mr-0 pr-0">
              <b-alert show variant="info" class="col-md-12">
                <b-row class="col-md-12">
                  <b-col
                    class="col-md-12 mt-2 mb-2 text-center pointer"
                    @click="triggerUploadFile('proof_of_business')"
                  >
                    <div>
                      <feather-icon icon="UploadIcon" size="25" />
                      <span></span>
                      {{ $t("upload_a_file_or_drag_files_here") }}
                    </div>
                  </b-col>
                </b-row>
              </b-alert>
            </b-row>
            <br />
            
          </div>
          <div v-else>
            <span class="uploaded-text"
              >{{ $t("uploaded") }}
              <feather-icon icon="CheckIcon"></feather-icon>
            </span>
          </div>
        </div>
      </b-row>
    </div>
    <div class="mandatory-documents-passport-back">
      <b-row>
        <div class="col-xl-4 col-lg-4 col-md-12">
          <h6 class="mandatory-documents-label">
            <span>{{ $t("shareholder_registry") }}</span>
          </h6>
        </div>

        <div class="col-xl-8 col-lg-8 col-md-12">
          <div class="mb-1 font-weight-bolder">
            {{ $t("shareholder_registry_text") }}
          </div>
          <div class="mb-1" v-if="shareholder_registryFile != null">
            {{ shareholder_registryFile }}
            <feather-icon
              @click="removeFile('shareholder_registry', shareholder_registryFile)"
              class="pointer text-danger"
              v-if="!hideButton"
              icon="Trash2Icon"
              size="18"
            />
          </div>
          <div v-if="shareholder_registryFile == null">
            <b-form-file
              style="display: none"
              @change="uploadFile($event, shareholder_registry, 'shareholder_registry')"
              v-model.trim="shareholder_registry"
              :placeholder="$t('upload_a_file_or_drag_files_here')"
              drop-placeholder="Drop file here..."
              ref="shareholder_registry"
            ></b-form-file>

            <b-row class="col-md-6 mr-0 pr-0">
              <b-alert show variant="info" class="col-md-12">
                <b-row class="col-md-12">
                  <b-col
                    class="col-md-12 mt-2 mb-2 text-center pointer"
                    @click="triggerUploadFile('shareholder_registry')"
                  >
                    <div>
                      <feather-icon icon="UploadIcon" size="25" />
                      <span></span>
                      {{ $t("upload_a_file_or_drag_files_here") }}
                    </div>
                  </b-col>
                </b-row>
              </b-alert>
            </b-row>
            <br />
            
          </div>
          <div v-else>
            <span class="uploaded-text"
              >{{ $t("uploaded") }}
              <feather-icon icon="CheckIcon"></feather-icon>
            </span>
          </div>
        </div>
      </b-row>
    </div>
        
 </div>
          <div
            class="mt-1"
            v-if="errors.length > 0"
            v-for="item in errors"
            :key="item"
          >
            <div class="alert alert-danger">
              <div class="col-md-12 p-2">
                {{ item }}
              </div>
            </div>
          </div>
          <b-row class="mt-2 mb-1" >
            <b-col class="col-md-12" align="end">
              <b-button variant="primary" @click="uploadFiles()">{{
                $t("save")
              }}</b-button>
            </b-col>
          </b-row>
        </div>
      </b-card-text>
      <div class="col-12" v-if="showLoader">
        <img src="/new-loader.svg" />
      </div>
    </b-card>
  </template>
  
  <script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required, email } from "@validations";
  import { VueAutosuggest } from "vue-autosuggest";
  import axios from 'axios';
  import {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckbox,
    BAlert,
    BInputGroupPrepend,
  } from "bootstrap-vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent";
  export default {
    name: "UploadIdentificationDocuments",
    components: {
      BCard,
      BRow,
      BCol,
      BForm,
      BButton,
      BFormGroup,
      BFormSelect,
      BFormInput,
      BInputGroup,
      BFormCheckbox,
      BFormDatepicker,
      BFormRadio,
      BFormRadioGroup,
      BAlert,
      BInputGroupPrepend,
      ValidationProvider,
      ValidationObserver,
      VueAutosuggest,
    },
    data() {
      return {
        passport_front: null,
        file_passport_front_old: null,
        file_passport_back_old: null,
        file_utility_bill_old: null,
        file_passport_back_included: false,
        passport_back: null,
        selfie: null,
        utility_bill: null,
        passportFrontFile: null,
        passportBackFile: null,
        selfieFile: null,
        utilityBillFile: null,
        passport_back_included_in_passport_front_file: null,
        code: null,
        files: [],
        errors: [],
        hideButton: true,
        showLoader: false,
        loader: false,
        sumsubToken: null,
        certificate_of_incorporation: null,
        certificate_of_incorporationFile: null,
        articles_of_association: null,
        articles_of_associationFile: null,
        extract_company_registry: null,
        extract_company_registryFile: null,
        proof_of_business: null,
        proof_of_businessFile: null,
        shareholder_registry: null,
        shareholder_registryFile: null,
        enableButton:false,
        identificationDocs: [
          {passport_front:null, passport_back:null,passportFrontFile:null,passportBackFile:null,file_passport_back_included:false}
        ],
        list: [],
      };
    },
    computed: {
     isEnabled(){
      return (
         this.passportFrontFile &&
           this.certificate_of_incorporationFile &&
           this.articles_of_associationFile &&
           this.extract_company_registryFile &&
           this.proof_of_businessFile &&
          this.shareholder_registryFile &&
          (this.file_passport_back_included == "true" || this.passportBackFile)
      );
     }
  },
  
    created() {
      this.getUser();
    },
    methods: {
      openPdf() {
         const pdfUrl = '/BO-pdf.pdf';
         window.open(pdfUrl, '_blank');
      },
      getUser() {
        this.showLoader = true;
        this.$http.post(`/buyer/admin/show/${this.$route.params.id}`, {}).then((res) => {
          if (res.data.value && res.data.value.identification_documentsBoD) {
            console.log('true')
            this.hideButton = false;
            this.code = res.data.value.identifiaction_documents
              ? res.data.value.identifiaction_documents.id_code
              : "";
  
            this.documents = res.data.value.identification_documentsBoD;
            // this.passport_front = this.documents.passport_front ?  this.documents.passport_front : null ;
            // this.passport_back = this.documents.passport_back ?  this.documents.passport_back : null ;
            // this.passportFrontFile = this.documents.passport_front ?  this.documents.passport_front : null ;
            // this.passportBackFile = this.documents.passport_back ?  this.documents.passport_back : null ;

            this.certificate_of_incorporation = this.documents.certificate_of_incorporation ?  this.documents.certificate_of_incorporation : null ;
            this.articles_of_association = this.documents.articles_of_association ?  this.documents.articles_of_association : null ;
            this.extract_company_registry = this.documents.extract_company_registry ?  this.documents.extract_company_registry : null ;
            this.proof_of_business = this.documents.proof_of_business ?  this.documents.proof_of_business : null ;
            this.shareholder_registry = this.documents.shareholder_registry ?  this.documents.shareholder_registry : null ;
            this.certificate_of_incorporationFile = this.documents.certificate_of_incorporation ?  this.documents.certificate_of_incorporation : null ;
            this.articles_of_associationFile = this.documents.articles_of_association ?  this.documents.articles_of_association : null ;
            this.extract_company_registryFile = this.documents.extract_company_registry ?  this.documents.extract_company_registry : null ;
            this.proof_of_businessFile = this.documents.proof_of_business ?  this.documents.proof_of_business : null ;
            this.shareholder_registryFile = this.documents.shareholder_registry ?  this.documents.shareholder_registry : null ;

            let front_passports = JSON.parse(this.documents.passport_front);
            let back_passports = JSON.parse(this.documents.passport_back);
            
            if(this.documents.passport_front!=null){
              front_passports.map((i, index)=>{
                  console.log(this.identificationDocs[index]);
                if(typeof this.identificationDocs[index]=='undefined'){
                  this.addAnotherBod();
                }
                this.identificationDocs[index].passportFrontFile = i;
                this.identificationDocs[index].passportBackFile = back_passports[index];
              });
            }else if(this.documents.passport_back!=null){
              back_passports.map((i, index)=>{
                if(typeof this.identificationDocs[index]=='undefined'){
                  this.addAnotherBod();
                }
                this.identificationDocs[index].passportBackFile = back_passports[index];
              });
            }


        //  if( this.passportFrontFile &&
        //        this.certificate_of_incorporationFile &&
        //        this.articles_of_associationFile &&
        //        this.extract_company_registryFile &&
        //        this.proof_of_businessFile &&
        //        this.shareholder_registryFile &&
        //        this.passportBackFile){
        //        this.$router.push("/cookpit/task/activate-account");
        //   }
          
          }
          this.showLoader = false;
        });
      },
      alreadyIncludedInFrontFile(index) {
        this.identificationDocs[index].file_passport_back_included = !this.identificationDocs[index].file_passport_back_included
      },
      addAnotherBod(){
        this.identificationDocs.push(
          {passport_front:null, passport_back:null,passportFrontFile:null,passportBackFile:null,file_passport_back_included:false}
        )
      },
      removeFile(type, file, index=null) {
        if (type == "passport_front") {
          this.identificationDocs[index].passportFrontFile = null;
          this.identificationDocs[index].passport_front = null;
          this.identificationDocs[index].file_passport_front_old = null;
        }
        if (type == "passport_back") {
          this.identificationDocs[index].passportBackFile = null;
          this.identificationDocs[index].passport_back = null;
          this.identificationDocs[index].file_passport_back_old = null;
        }
  
        if (type == "certificate_of_incorporation") {
          this.certificate_of_incorporation = null;
          this.certificate_of_incorporationFile = null;
        }
        
        if (type == "articles_of_association") {
          this.articles_of_association = null;
          this.articles_of_associationFile = null;
        }
        
        if (type == "extract_company_registry") {
          this.extract_company_registry = null;
          this.extract_company_registryFile = null;
        }
        
        if (type == "proof_of_business") {
          this.proof_of_business = null;
          this.proof_of_businessFile = null;
        }
        
        if (type == "shareholder_registry") {
          this.shareholder_registry = null;
          this.shareholder_registryFile = null;
        }

        this.files.forEach((el, index) => {
          if (el.documentType == type) {
            this.files.splice(index, 1);
          }
        });
      },
      uploadFiles() {
        let formData = new FormData();
        this.files.forEach((el) => {
          formData.append(el.documentType, el.el);
        });
        this.list.forEach((el) => {
          formData.append(el.documentType, el.el);
        });
        this.identificationDocs.map((i, index)=>{
          formData.append('file_passport_back_included_'+index, i.file_passport_back_included);
        })
        formData.append('bod_members',this.identificationDocs.length);
        this.showLoader = true;
        let config = { headers: { "Content-Type": "multipart/form-data" } };
        this.$http
          .post(`/buyer/document/uploadMultipleBoD/${this.$route.params.id}`, formData, config )
          .catch((err) => {
            if (err) {
              this.errors.push(err.data.message);
              this.showLoader = false;
            }
          })
          .then((res) => {
            if (res.data.status == 200) {
              if(res.data.value.passport_front != null 
                && res.data.value.passport_back != null
                && res.data.value.articles_of_association != null
                && res.data.value.certificate_of_incorporation != null
                && res.data.value.proof_of_business != null
                && res.data.value.shareholder_registry != null
                && res.data.value.extract_company_registry != null
                 )
            {
               this.showSuccessMessage();
               this.$router.push("/cookpit/task/activate-account");
            }
            }
            if (res.data.status == 500) {
              this.errors.push(res.data.message);
            }
            this.showLoader = false;
          })
          .finally(() => {
          this.showLoader = false;
          });;
      },
      showSuccessMessage() {
        let text = this.$t("success");
        this.$swal({
          position: "center",
          icon: "success",
          title: text,
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      },
      uploadFile(event, file, documentType,index = null) {
        event.target.files.forEach((el) => {
          this.files.push({ documentType, el });
          if (documentType == "certificate_of_incorporation") {
            this.certificate_of_incorporationFile = el.name;
          }
          if (documentType == "articles_of_association") {
            this.articles_of_associationFile = el.name;
          }
          if (documentType == "extract_company_registry") {
            this.extract_company_registryFile = el.name;
          }
          if (documentType == "proof_of_business") {
            this.proof_of_businessFile = el.name;
          }
          if (documentType == "shareholder_registry") {
            this.shareholder_registryFile = el.name;
          }
        });
        console.log('filesss', this.identificationDocs)
      },
      uploadFile2(event, file, documentType, index = null) {
  this.list = this.list || [];
  event.target.files.forEach((el) => {
    const fileObject = { documentType: `${documentType}_${index}`, el };
    this.list.push(fileObject);
    let propertyName = '';
    if(documentType == 'passport_front'){
         propertyName = `passportFrontFile`;
    }else if(documentType == 'passport_back'){
       propertyName = `passportBackFile`;
    }
    if (index !== null) {
      this.$set(this.identificationDocs[index], propertyName, el.name);
    }
  });
},
      triggerUploadFile(documentType) {
        this.$refs[documentType].$refs.input.click();
      },
      triggerUploadFile2(documentType) {
        document.getElementById(documentType).click()
      },
    },
  };
  </script>
  
  <style scoped>
  .mandatory-documents-title {
    color: #1586c4;
    border-left: 2px solid #1586c4;
    padding-left: 8px;
    margin-bottom: 30px;
  }
  .mandatory-documents-passport-front {
    padding: 30px;
    background-color: #faf9f9;
  }
  .mandatory-documents-passport-back {
    padding: 30px;
    background-color: #faf9f9;
  }
  .mandatory-documents-passport-utility-bill {
    padding: 30px;
    background-color: #faf9f9;
  }
  .mandatory-documents-passport-selfie {
    padding: 30px;
    background-color: #ffffff;
  }
  .mandatory-documents-label {
    display: flex;
    justify-content: start;
  }
  .mandatory-documents-label > span {
    color: #1586c4;
    border-bottom: 1px solid #1586c4;
    padding-bottom: 4px;
    font-size: 15px;
  }
  .selfie-fundamentals {
    padding: 10px;
  }
  .uploaded-text {
    padding: 10px 30px;
    background-color: #55dd92;
    border-radius: 5px;
    color: white;
  }
  </style>
  