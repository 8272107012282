<template>
  <div>
    <div v-if="company">
      <b-alert :variant="alertVariantStatus()" show class="p-2" v-show="showBox">
        <p>{{ boxTitle }}</p>
        <b-row>
          <b-col class="col-xl-9 col-lg-9 col-md-12">
            <b-form-textarea v-model="reason" style="height: 95px" placeholder="Reason"></b-form-textarea>
          </b-col>
          <b-col class="col-xl-3 col-lg-3 col-md-12 text-right">
            <b-button variant="danger" style="width: 100%" @click="updateCompanyStatus('decline')"
              class="mr-1"><feather-icon icon="TrashIcon"></feather-icon> Decline</b-button>
            <br />
            <br />
            <b-button variant="success" style="width: 100%" @click="updateCompanyStatus('approve')"><feather-icon
                icon="CheckIcon"></feather-icon> Approve</b-button>
          </b-col>
        </b-row>
      </b-alert>
      <div class="row">
        <div class="col-md-6">
          <h3 class="light-blue">{{ $t("buyer_details") }}</h3>
          <p>
            {{ $t("we_need_some_basic_data_from_you") }}
          </p>
        </div>
        <b-col class="col-md-6 aling-end" align="end">
          <h4 class="light-blue">
            {{ $t("status") }}:
            <span pill variant="light-success" v-if="status == 1">
              {{ $t("active") }}
            </span>
            <span pill variant="light-secondary" v-else>
              {{ $t("inactive") }}
            </span>
            <br />
            <b-button @click="syncWithSumSub()" variant="primary" class="mr-1 mt-1">{{ $t("sumsub_sync") }}</b-button>
            <b-button @click="enableCompany(0)" variant="danger" class="mr-1 mt-1">{{ $t("disable") }}</b-button>
            <b-button @click="enableCompany(1)" variant="success mt-1">{{ $t("enable") }}</b-button>
          </h4>
        </b-col>
      </div>
      <validation-observer ref="buyerRegisterValidation" name="RegisterBuyer">
        <b-row>
          <b-col cols="12">
            <b-button :variant="!displayCompany ? 'primary' : 'outline-primary'" @click="changeInvestorStatus()">
              <feather-icon icon="user"></feather-icon>
              {{ $t("i_am_investing_as_private_person") }}
            </b-button>
            <b-button :variant="displayCompany ? 'primary' : 'outline-primary'" class="ml-1"
              @click="changeInvestorStatus()">
              <feather-icon icon="briefcase"></feather-icon>
              {{ $t("i_am_investing_in_the_name_of_a_company") }}
            </b-button>
          </b-col>
        </b-row>
        <b-form @submit="onSubmit" class="mt-1" @reset="onReset">
          <b-card>
            <h5 v-if="displayCompany">{{ $t("company_information") }}</h5>
            <b-row m-0 p-0 v-if="displayCompany">
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group :label="$t('company_name') + ':'">
                  <validation-provider #default="{ errors }" name="Company name">
                    <b-input-group class="input-group-merge">
                      <b-form-input v-model="main_contact_company_name" :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"></b-form-input>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group :label="$t('legal_form') + ':'">
                  <validation-provider #default="{ errors }" name="Company Legal Form">
                    <b-form-select v-model.trim="companyInfo.legal_form" :options="[
                      { value: 0, text: $t('sole_proprietorship') },
                      { value: 1, text: $t('general_partnership') },
                      { value: 2, text: $t('limited_partnership') },
                      { value: 3, text: $t('corporation') },
                      { value: 4, text: $t('limited_liability_company') },
                      { value: 5, text: $t('cooperative') },
                    ]" :state="errors.length > 0 ? false : null"></b-form-select>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group :label="$t('registration_no') + ':'">
                  <validation-provider #default="{ errors }" name="Registration no">
                    <b-input-group class="input-group-merge">
                      <b-form-input id="registration_no" v-model="companyInfo.registration_no"
                        :state="errors.length > 0 ? false : null"></b-form-input>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="4" md="4" lg="4">
                <b-form-group id="street_name" :label="$t('address') + ':'" label-for="address">
                  <validation-provider #default="{ errors }" name="street_name">
                    <b-form-input id="street_name" v-model="companyInfo.street_name"
                      :state="errors.length > 0 ? false : null"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="2" md="2" lg="2">
                <b-form-group id="street_number" :label="$t('street_nr') + ':'" label-for="street_number">
                  <validation-provider #default="{ errors }" name="street_number">
                    <b-form-input id="street_number" v-model="companyInfo.street_number"
                      :state="errors.length > 0 ? false : null"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="2" md="2" lg="2">
                <b-form-group id="zip-group" :label="$t('zip') + ':'" label-for="zip">
                  <validation-provider #default="{ errors }" name="zip">
                    <b-form-input id="zip" v-model="companyInfo.zip"
                      :state="errors.length > 0 ? false : null"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="4" md="4" lg="4">
                <b-form-group id="city-group" :label="$t('city') + ':'" label-for="city">
                  <validation-provider #default="{ errors }" name="city">
                    <b-form-input id="city" v-model="companyInfo.city"
                      :state="errors.length > 0 ? false : null"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="country_name" :label="$t('country_name') + ':'" label-for="country_name">
                  <validation-provider #default="{ errors }" name="country_name">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserPlusIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-select id="country_name" v-model="companyInfo.country" :options="$store.state.app.language == 'en'
                          ? nationalities
                          : nationalities_de
                        " :state="errors.length > 0 ? false : null"></b-form-select>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6"></b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group :label="$t('business_address') + ' ?'" v-slot="{ ariaDescribedby }">
                  <b-button :variant="bbAdVl == 1 ? 'primary' : 'outline-primary'" @click="bbAdVl = 1" class="mr-1">
                    {{ $t('yes') }}
                  </b-button>
                  <b-button :variant="bbAdVl == 0 ? 'primary' : 'outline-primary'" @click="bbAdVl = 0">
                    {{ $t('no') }}
                  </b-button>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6"></b-col>
              <b-row class="p-1" v-show="bbAdVl != 0">
                <b-col cols="12" xl="4" md="4" lg="4">
                  <b-form-group :label="$t('address') + ':'">
                    <validation-provider #default="{ errors }" name="address">
                      <b-form-input v-model="companyInfo.businessAddress_street_name"
                        :state="errors.length > 0 ? false : null"></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" xl="2" md="2" lg="2">
                  <b-form-group :label="$t('street_nr') + ':'">
                    <validation-provider #default="{ errors }">
                      <b-form-input v-model="companyInfo.businessAddress_street_number"
                        :state="errors.length > 0 ? false : null"></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" xl="2" md="2" lg="2">
                  <b-form-group id="zip-group" :label="$t('zip') + ':'" label-for="zip">
                    <validation-provider #default="{ errors }" name="zip">
                      <b-form-input id="zip" v-model="companyInfo.businessAddress_zip"
                        :state="errors.length > 0 ? false : null"></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" xl="4" md="4" lg="4">
                  <b-form-group id="city-group" :label="$t('city') + ':'" label-for="city">
                    <validation-provider #default="{ errors }" name="city">
                      <b-form-input id="city" v-model="companyInfo.businessAddress_city"
                        :state="errors.length > 0 ? false : null"></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12" xl="6" md="6" lg="6">
                  <b-form-group :label="$t('country_name') + ':'">
                    <validation-provider #default="{ errors }" name="Country">
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="UserPlusIcon" class="cursor-pointer" />
                        </b-input-group-prepend>
                        <b-form-select v-model="companyInfo.businessAddress_country" :options="$store.state.app.language == 'en'
                            ? nationalities
                            : nationalities_de
                          " :state="errors.length > 0 ? false : null"></b-form-select>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group :label="$t('website') + ':'">
                  <validation-provider #default="{ errors }" name="Website">
                    <b-input-group class="input-group-merge">
                      <b-form-input v-model="companyInfo.website" :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"></b-form-input>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group :label="$t('business_activity') + ':'">
                  <validation-provider #default="{ errors }" name="business_activity">
                    <b-input-group class="input-group-merge">
                      <b-form-input v-model="industry" :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"></b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group :label="$t('lisence') + ' ?'" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group v-model="companyInfo.license" :options="[
                    { text: $t('yes'), value: 1 },
                    { text: $t('no'), value: 0 },
                  ]" :aria-describedby="ariaDescribedby"></b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group :label="$t('date_of_inc') + ':'">
                  <validation-provider #default="{ errors }" :name="$t('date_of_inc')">
                    <b-form-datepicker no-flip v-model="companyInfo.dateOfInc" :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"></b-form-datepicker>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group :label="$t('tax_residence') + ':'" label-for="main-contact-country-id">
                  <validation-provider #default="{ errors }" name="Country">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserPlusIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-select v-model="companyInfo.tax_residence" :options="$store.state.app.language == 'en'
                          ? nationalities
                          : nationalities_de
                        " :state="errors.length > 0 ? false : null"></b-form-select>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group :label="$t('vat') + ':'">
                  <validation-provider #default="{ errors }">
                    <b-input-group class="input-group-merge">
                      <b-form-input v-model="companyInfo.vat" :state="errors.length > 0 ? false : null"></b-form-input>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group :label="$t('tin') + ':'">
                  <validation-provider #default="{ errors }" :name="$t('tin')">
                    <b-input-group class="input-group-merge">
                      <b-form-input v-model="companyInfo.tin" :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"></b-form-input>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group :label="$t('company_entitled') + ' ?'" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group v-model="companyInfo.companyEntitled" :options="[
                    { text: $t('yes'), value: 1 },
                    { text: $t('no'), value: 0 },
                  ]" :aria-describedby="ariaDescribedby"></b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group :label="$t('usTaxPayer') + ' ?'" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group v-model="companyInfo.usTaxPayer" :options="[
                    { text: $t('yes'), value: 1 },
                    { text: $t('no'), value: 0 },
                  ]" :aria-describedby="ariaDescribedby" name="debt-enforcement"></b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-row>
            <h5>{{ $t("personal_info") }}</h5>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="main-contact-gender-group" :label="$t('salutation') + ':'"
                  label-for="main-contact-gender">
                  <validation-provider #default="{ errors }" name="main-contact-gender">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-select id="main-contact-gender" v-model="main_contact_gender" :options="[
                        {
                          text: $t('select_one'),
                          value: null,
                          disabled: true,
                        },
                        { text: $t('mr'), value: '0' },
                        { text: $t('mrs'), value: '1' },
                      ]" :state="errors.length > 0 ? false : null"></b-form-select>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="main-contact-first-name-group" :label="$t('first_name') + ':'"
                  label-for="main-contact-first-name">
                  <validation-provider #default="{ errors }" name="First name">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-input v-model="main_contact_first_name" :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"></b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="main-contact-last-name-group" :label="$t('last_name') + ':'"
                  label-for="main-contact-last-name">
                  <validation-provider #default="{ errors }" name="Last Name">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-input id="main-contact-last-name" v-model="main_contact_last_name" :class="main_contact_last_name != null
                          ? ''
                          : showBlueBorder
                            ? 'is-empty'
                            : ''
                        " :state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-input>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="main-contact-birthday-group" :label="$t('birthday') + ':'" label-for="main-contact-tel">
                  <validation-provider #default="{ errors }" name="Birthday">
                    <b-form-datepicker no-flip id="birhtday" :max="ageRestriction()" v-model="main_contact_birthday"
                      :state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-datepicker>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="main-contact-nationality-group" :label="$t('nationality') + ':'"
                  label-for="main-contact-nationality">
                  <validation-provider #default="{ errors }" name="Nationality">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserPlusIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-select id="nationality" v-model="main_contact_nationality" :options="$store.state.app.language == 'en'
                          ? nationalities
                          : nationalities_de
                        " :state="errors.length > 0 ? false : null"></b-form-select>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="main-contact-email-group" :label="$t('company_email_address') + ':'"
                  label-for="main-contact-email">
                  <validation-provider #default="{ errors }" name="Contact Email">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MailIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-input id="main-contact-email" v-model="main_contact_email"
                        :state="errors.length > 0 ? false : null"></b-form-input>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <b-form-group id="tel-group" :label="$t('tel') + ':'" label-for="tel">
                  <validation-provider #default="{ errors }" name="tel">
                    <b-input-group>
                      <b-input-group-prepend id="phoneCountries">
                        <b-form-select :options="countries" v-model="phoneContries">
                        </b-form-select>
                      </b-input-group-prepend>
                      <b-form-input id="main-contact-tel" v-model="main_contact_tel" @input="checkPhoneFormat"
                        class="form-control" :state="errors.length > 0 ? false : null">
                      </b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger" v-if="phoneValidation">{{
                      phoneValidation
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="4" md="4" lg="4">
                <b-form-group id="main-contact-address-group" :label="$t('address') + ':'"
                  label-for="main-contact-address">
                  <validation-provider #default="{ errors }" name="main-contact-address">
                    <b-form-input id="main-contact-address" v-model="main_contact_address"
                      :state="errors.length > 0 ? false : null"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="2" md="2" lg="2">
                <b-form-group id="main-contact-address-nr-group" :label="$t('street_nr') + ':'"
                  label-for="main-contact-address-nr">
                  <validation-provider #default="{ errors }" name="main-contact-address-nr">
                    <b-form-input id="main-contact-address-nr" v-model="main_contact_address_nr"
                      :state="errors.length > 0 ? false : null"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="2" md="2" lg="2">
                <b-form-group id="zip-group" :label="$t('zip') + ':'" label-for="zip">
                  <validation-provider #default="{ errors }" name="zip">
                    <b-form-input id="zip" v-model="main_contact_zip"
                      :state="errors.length > 0 ? false : null"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="4" md="4" lg="4">
                <b-form-group id="city-group" :label="$t('city') + ':'" label-for="city">
                  <validation-provider #default="{ errors }" name="city">
                    <b-form-input id="city" v-model="main_contact_city"
                      :state="errors.length > 0 ? false : null"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="main-contact-country-group" :label="$t('country_name') + ':'"
                  label-for="main-contact-country-id">
                  <validation-provider #default="{ errors }" name="Country">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserPlusIcon" class="cursor-pointer" />
                      </b-input-group-prepend>

                      <b-form-select id="signing-authority-type" v-model="main_contact_country_id" :options="$store.state.app.language == 'en'
                          ? nationalities
                          : nationalities_de
                        " :state="errors.length > 0 ? false : null"></b-form-select>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="main-contact-position-group" :label="$t('position') + ':'"
                  label-for="main-contact-position">
                  <validation-provider #default="{ errors }" name="Position">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserPlusIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-select id="position" v-model="main_contact_position" :options="[
                        {
                          text: $t('select_one'),
                          value: null,
                          disabled: true,
                        },
                        { text: $t('employed'), value: '1' },
                        { text: $t('self-employed'), value: '2' },
                        { text: $t('retired'), value: '3' },
                        { text: $t('etc'), value: '0' },
                      ]" :state="errors.length > 0 ? false : null"></b-form-select>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="industry-group" :label="$t('business_activity') + ':'" label-for="industry">
                  <validation-provider #default="{ errors }" name="industry">
                    <b-form-input id="industry" v-model="main_contact_industry"
                      :state="errors.length > 0 ? false : null"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <h5>
            {{ $t("bank_information") }}
          </h5>

          <b-card>
            <b-row m-0 p-0>
              <b-col cols="6">
                <b-form-group id="bank_name" :label="$t('bank_name') + ':'" label-for="bank_name">
                  <validation-provider #default="{ errors }" name="Bank Name">
                    <b-form-input id="bank_name" v-model.trim="bank_name"
                      :state="errors.length > 0 ? false : null"></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>
            <b-row m-0 p-0>
              <b-col cols="6">
                <b-form-group id="iban-group" :label="$t('iban') + ':'" label-for="iban">
                  <validation-provider #default="{ errors }" name="iban">
                    <b-form-input id="iban" v-model.trim="iban" @blur="checkIban()" :min="18"
                      :state="errors.length > 0 ? false : null"></b-form-input>
                    <small v-if="invalidIbanError" class="text-danger">Invalid Iban</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group id="bic" :label="$t('bic') + ':'" label-for="bic">
                  <validation-provider #default="{ errors }" name="Bic">
                    <b-form-input id="bic" v-model.trim="bic" :state="errors.length > 0 ? false : null"></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <h5>{{ $t("documents") }}</h5>
          <b-card>
            <b-row m-0 p-0>
              <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <h6 class="mandatory-documents-label">
                  <span>{{ $t("passport_id_front") }}</span>
                </h6>
              </div>
              <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                <div class="mb-1 font-weight-bolder">
                  {{ $t("passport_front_text") }}
                </div>
                <div class="mb-1" v-if="passportFrontFile != null">
                  {{ passportFrontFile }}
                  <feather-icon v-if="!hideButton" @click="removeFile('passport_front', passportFrontFile)"
                    class="pointer text-danger" icon="Trash2Icon" size="18" />
                </div>

                <div v-if="passportFrontFile == null">
                  <b-form-file style="display: none" @change="uploadFile($event, passport_front, 'passport_front')"
                    v-model.trim="passport_front" :placeholder="$t('upload_a_file_or_drag_files_here')"
                    drop-placeholder="Drop file here..." ref="passport_front"></b-form-file>

                  <b-row class="col-lg-6 col-md-12 mr-0 pr-0">
                    <b-alert show variant="info" class="col-md-12">
                      <b-row class="col-md-12">
                        <b-col class="col-md-12 mt-2 mb-2 text-center pointer"
                          @click="triggerUploadFile('passport_front')">
                          <div>
                            <feather-icon icon="UploadIcon" size="25" />
                            <span></span>
                            {{ $t("upload_a_file_or_drag_files_here") }}
                          </div>
                        </b-col>
                      </b-row>
                    </b-alert>
                  </b-row>
                </div>
                <div v-else class="mb-1">
                  <span class="uploaded-text">{{ $t("uploaded") }}
                    <feather-icon icon="CheckIcon"></feather-icon>
                  </span>
                </div>
              </div>
            </b-row>
            <b-row m-0 p-0>
              <div class="col-xl-3 col-lg-4 col-md-12">
                <h6 class="mandatory-documents-label">
                  <span>{{ $t("passport_id_back") }}</span>
                </h6>
              </div>

              <div class="col-xl-9 col-lg-8 col-md-12">
                <div class="mb-1 font-weight-bolder">
                  {{ $t("passport_back_text") }}
                </div>
                <div class="mb-1" v-if="passportBackFile != null">
                  {{ passportBackFile }}
                  <feather-icon @click="removeFile('passport_back', passportBackFile)" class="pointer text-danger"
                    v-if="!hideButton" icon="Trash2Icon" size="18" />
                </div>
                <div v-if="passportBackFile == null">
                  <b-form-file style="display: none" @change="uploadFile($event, passport_back, 'passport_back')"
                    v-model.trim="passport_back" :placeholder="$t('upload_a_file_or_drag_files_here')"
                    drop-placeholder="Drop file here..." ref="passport_back"></b-form-file>

                  <b-row class="col-md-6 mr-0 pr-0">
                    <b-alert show variant="info" class="col-md-12">
                      <b-row class="col-md-12">
                        <b-col class="col-md-12 mt-2 mb-2 text-center pointer"
                          @click="triggerUploadFile('passport_back')">
                          <div>
                            <feather-icon icon="UploadIcon" size="25" />
                            <span></span>
                            {{ $t("upload_a_file_or_drag_files_here") }}
                          </div>
                        </b-col>
                      </b-row>
                    </b-alert>
                  </b-row>
                  <br />
                  <b-form-group id="confirmation_checkbox">
                    <validation-provider #default="{ errors }" name="passport_back_included_in_passport_front_file">
                      <b-form-checkbox id="checkbox-1" v-model.trim="file_passport_back_included"
                        name="passport_back_included_in_passport_front_file" value="true" unchecked-value="false"
                        @change="alreadyIncludedInFrontFile">
                        {{ $t("already_included_in_front_file") }}
                      </b-form-checkbox>
                      <small class="text-danger">{{ $t(errors[0]) }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div v-else>
                  <span class="uploaded-text">{{ $t("uploaded") }}
                    <feather-icon icon="CheckIcon"></feather-icon>
                  </span>
                </div>
              </div>
            </b-row>
            <b-row v-if="passportFrontFile == null || passportBackFile == null" class="mt-2"> <b-button
                @click="uploadIds()"> {{ $t("task_2_buyer_description") }}</b-button></b-row>
          </b-card>
          <h5>{{ $t("financial_situation") }}</h5>
          <b-card style="padding: 10px" class="mb-0">
            <b-row m-0 p-0>
              <b-col cols="8" m-0 p-0>
                <!-- terms-and-conditions -->
                <b-form-group>
                  <div class="p-0">
                    <h6 class="">
                      {{ $t("financial_buyer_text_1") }}
                    </h6>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="light-blue">
              {{ $t("please_answer_this_questions") }}
              <feather-icon icon="ArrowDownCircleIcon" size="18" />
            </div>
            <br />
            <b-row v-for="(item, index) in financial_questions" :key="index">
              <b-col cols="12">
                <span>{{ item.question }}
                  <feather-icon icon="HelpCircleIcon" role="button" v-b-tooltip.hover
                    :title="$t('tooltip_financial_' + index)" size="18" /></span>
                <div class="mt-1">
                  <b-button :variant="item.answer == 1 ? 'primary' : 'outline-primary'" class="width-100"
                    @click="item.answer = 1">{{ $t("yes") }}</b-button>
                  <b-button :variant="item.answer == 0 ? 'primary' : 'outline-primary'" class="width-100 ml-1"
                    @click="item.answer = 0">{{ $t("no") }}</b-button>
                </div>
                <hr />
              </b-col>
            </b-row>
          </b-card>
          <b-card style="padding: 10px" class="mt-0 pt-0">
            <b-row m-0 p-0>
              <b-col cols="12" m-0 p-0>
                <!-- terms-and-conditions -->
                <b-form-group>
                  <div class="p-0">
                    <h6>
                      {{ $t("qualification_buyer_text_1") }}
                    </h6>
                    <div class="light-blue">
                      {{ $t("please_answer_this_questions") }}
                      <feather-icon icon="ArrowDownCircleIcon" size="18" />
                    </div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <span> {{ $t("political_q_1") }}
                  <feather-icon icon="HelpCircleIcon" size="18" role="button" v-b-tooltip.hover
                    :title="$t('political_q_1')" /></span>
                <div class="mt-1">
                  <b-button :variant="political_a_1 == 1 ? 'primary' : 'outline-primary'" class="width-100"
                    @click="political_a_1 = 1">{{ $t("yes") }}</b-button>
                  <b-button :variant="political_a_1 == 0 ? 'primary' : 'outline-primary'" class="width-100 ml-1"
                    @click="political_a_1 = 0">{{ $t("no") }}</b-button>
                </div>

              </b-col>
              <b-row v-if="political_a_1 == 1" class="col-12 mt-1">
                <b-col cols="12" xl="6" md="6" lg="6">
                  <b-form-group :label="$t('country_name') + ':'">
                    <validation-provider #default="{ errors }" name="Country">
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text :class="pa1_country != null
                            ? ''
                            : showBlueBorder
                              ? 'is-empty'
                              : ''
                          ">
                          <feather-icon icon="UserPlusIcon" class="cursor-pointer" />
                        </b-input-group-prepend>

                        <b-form-select v-model="pa1_country" :class="pa1_country != null
                            ? ''
                            : showBlueBorder
                              ? 'is-empty'
                              : ''
                          " :options="$store.state.app.language == 'en'
                              ? nationalities
                              : nationalities_de
                        " :state="errors.length > 0 ? false : null"></b-form-select>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" xl="6" md="6" lg="6">
                  <validation-provider #default="{ errors }" name="Position">
                    <b-form-group :label="$t('role') + ':'" label-for="role">
                      <validation-provider #default="{ errors }" name="Role">
                        <b-form-input id="role" v-model.trim="pa1_role"
                          :state="errors.length > 0 ? false : null"></b-form-input>
                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                      </validation-provider>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <hr />
              <b-col cols="12" class="mt-1">
                <span> {{ $t("political_q_2") }}
                  <feather-icon icon="HelpCircleIcon" size="18" role="button" v-b-tooltip.hover
                    :title="$t('political_q_2')" /></span>
                <div class="mt-1">
                  <b-button :variant="political_a_2 == 1 ? 'primary' : 'outline-primary'" class="width-100"
                    @click="political_a_2 = 1">{{ $t("yes") }}</b-button>
                  <b-button :variant="political_a_2 == 0 ? 'primary' : 'outline-primary'" class="width-100 ml-1"
                    @click="political_a_2 = 0">{{ $t("no") }}</b-button>
                </div>
                <b-row v-if="political_a_2 == 1" class="mt-1">
                  <b-col cols="12" xl="6" md="6" lg="6">
                    <b-form-group :label="$t('country_name') + ':'">
                      <validation-provider #default="{ errors }" name="Country">
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text :class="pa2_country != null
                              ? ''
                              : showBlueBorder
                                ? 'is-empty'
                                : ''
                            ">
                            <feather-icon icon="UserPlusIcon" class="cursor-pointer" />
                          </b-input-group-prepend>

                          <b-form-select id="signing-authority-type" v-model="pa2_country" :class="pa2_country != null
                              ? ''
                              : showBlueBorder
                                ? 'is-empty'
                                : ''
                            " :options="$store.state.app.language == 'en'
                              ? nationalities
                              : nationalities_de
                            " :state="errors.length > 0 ? false : null"></b-form-select>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" xl="6" md="6" lg="6">

                    <validation-provider #default="{ errors }" name="Position">
                      <b-form-group id="bank_name" :label="$t('role') + ':'" label-for="role">
                        <validation-provider #default="{ errors }" name="Role">
                          <b-form-input id="role" v-model.trim="pa2_role"
                            :state="errors.length > 0 ? false : null"></b-form-input>
                          <small class="text-danger">{{ $t(errors[0]) }}</small>
                        </validation-provider>
                      </b-form-group>
                    </validation-provider>

                  </b-col>
                  <b-col cols="12" xl="6" md="6" lg="6">
                    <b-form-group :label="$t('first_name') + ':'">
                      <validation-provider #default="{ errors }" name="First name">
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text :class="pa2_first_name != null
                              ? ''
                              : showBlueBorder
                                ? 'is-empty'
                                : ''
                            ">
                            <feather-icon icon="UserIcon" class="cursor-pointer" />
                          </b-input-group-prepend>
                          <b-form-input v-model="pa2_first_name" :class="pa2_first_name != null
                              ? ''
                              : showBlueBorder
                                ? 'is-empty'
                                : ''
                            " :state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-input>
                        </b-input-group>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" xl="6" md="6" lg="6">
                    <b-form-group :label="$t('last_name') + ':'">
                      <validation-provider #default="{ errors }" name="Last Name">
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text :class="pa2_last_name != null
                              ? ''
                              : showBlueBorder
                                ? 'is-empty'
                                : ''
                            ">
                            <feather-icon icon="UserIcon" class="cursor-pointer" />
                          </b-input-group-prepend>
                          <b-form-input id="main-contact-last-name" v-model="pa2_last_name" :class="pa2_last_name != null
                              ? ''
                              : showBlueBorder
                                ? 'is-empty'
                                : ''
                            " :state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-input>
                        </b-input-group>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
              </b-col>
            </b-row>
          </b-card>
          <h5>{{ $t("legal_notice") }}</h5>
          <b-card>
            <b-row m-0 p-0>
              <b-col cols="12">
                <!-- terms-and-conditions -->
                <b-form-group>
                  <validation-provider #default="{ errors }" name="terms-and-conditions">
                    <b-form-checkbox id="terms-and-conditions" v-model="terms_and_conditions" name="terms-and-conditions"
                      :state="errors.length > 0 ? false : null" :disabled="disableInputs">
                      {{ $t("agree_with_terms_buyer_before_link") }}
                      <span class="web">
                        <router-link to="/files/terms_de" target="_blank" v-if="$store.state.app.language == 'de'">{{
                          $t("agree_with_terms_buyer_link") }}</router-link>
                        <router-link to="/files/terms" target="_blank" v-else>{{
                          $t("agree_with_terms_buyer_link")
                        }}</router-link>
                      </span>
                      <span class="mobile">
                        <a v-if="$store.state.app.language == 'de'" href="/files/terms_de.pdf" download="">{{
                          $t("agree_with_terms_buyer_link") }}</a>
                        <a v-else href="/files/terms_eng.pdf" download="">{{
                          $t("agree_with_terms_buyer_link")
                        }}</a>
                      </span>
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <div style="background: #efefef; padding: 10px" class="mt-1">
                    <p>
                      {{ $t("agree_with_terms_buyer_0") }}
                    </p>
                    <div class="col-md-12">
                      <p>
                        {{ $t("agree_with_terms_buyer_1") }}
                      </p>
                      <p>
                        {{ $t("agree_with_terms_buyer_2") }}
                      </p>
                      <p>
                        {{ $t("agree_with_terms_buyer_3_before_link") }}

                        <span>
                          <span class="web">
                            <router-link to="/files/risk_de" target="_blank" v-if="$store.state.app.language == 'de'">{{
                              $t("agree_with_terms_buyer_3_link")
                            }}</router-link>
                            <router-link to="/files/risk" target="_blank" v-else>{{
                              $t("agree_with_terms_buyer_3_link")
                            }}</router-link>
                          </span>
                          <span class="mobile">
                            <a v-if="$store.state.app.language == 'de'" href="/files/risk_de.pdf" download="">{{
                              $t("agree_with_terms_buyer_3_link") }}</a>
                            <a v-else href="/files/risk_en.pdf" download="">{{
                              $t("agree_with_terms_buyer_3_link")
                            }}</a>
                          </span>

                          <span>
                            {{ $t("agree_with_terms_buyer_3_after_link") }}
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <b-row m-0 p-0>
            <b-col cols="12" class="text-right">
              <b-button variant="primary" @click="saveBuyerInfo()">
                <feather-icon icon="SaveIcon"></feather-icon>
                {{ $t("edit_information") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { VueAutosuggest } from "vue-autosuggest";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { required, email } from "@validations";
import Cleave from "vue-cleave-component";
import { phone } from "phone";
import moment from "moment";

import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BFormDatepicker,
    BFormRadio,
    BFormRadioGroup,
    BAlert,
    BInputGroupPrepend,
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
    Cleave,
  },
  name: "AddBuyer",
  data() {
    return {
      type: "private",
      loader: false,
      status: 0,
      countries_code: [],
      countries: [],
      showBlueBorder: false,
      nationalities: [],
      nationalities_de: [],
      positions: ["Employed", "Self Employed", "Retired", "Etc"],
      main_contact_gender: "",
      main_contact_email: "",
      main_contact_first_name: "",
      main_contact_last_name: "",
      main_contact_company_name: "",
      main_contact_birthday: "",
      main_contact_nationality: "",
      main_contact_zip: "",
      main_contact_address: "",
      main_contact_address_nr: "",
      main_contact_city: "",
      main_contact_country_id: "",
      main_contact_tel: "",
      main_contact_position: "",
      main_contact_industry: "",
      bbAdVl: 0,
      financial_questions: [
        { id: 1, question: this.$t("question_1"), answer: false },
        { id: 2, question: this.$t("question_2"), answer: false },
        { id: 3, question: this.$t("question_3"), answer: false },
      ],
      qualification_questions: [
        { id: 4, question: this.$t("question_4"), answer: false },
        { id: 5, question: this.$t("question_5"), answer: false },
        { id: 6, question: this.$t("question_6"), answer: false },
        { id: 7, question: this.$t("question_7"), answer: false },
      ],
      terms_and_conditions: false,
      disableInputs: false,
      reason: "",
      boxTitle: "",
      phoneOptions: {
        phone: {
          delimiters: [" ", " ", " "],
          blocks: [2, 3, 2, 2],
        },
      },
      phoneContries: "CH",
      phoneValidation: "",
      displayCompany: false,
      passportFrontFile: null,
      passport_front: null,
      passportBackFile: null,
      passport_back: null,
      file_passport_back_included: false,
      political_a_1: 0,
      political_a_2: 0,
      pa1_role: null,
      pa1_country: null,
      pa2_role: null,
      pa2_country: null,
      pa2_first_name: null,
      pa2_last_name: null,
      companyInfo: {
        legal_form: null,
        registration_no: null,
        street_name: null,
        street_number: null,
        zip: null,
        city: null,
        country: null,
        businessAddress_street_name: null,
        businessAddress_street_number: null,
        businessAddress_zip: null,
        businessAddress_city: null,
        businessAddress_country: null,
        website: null,
        businessActivity: null,
        license: null,
        dateOfInc: null,
        tax_residence: null,
        vat: null,
        tin: null,
        companyEntitled: null,
        usTaxPayer: null,
      },
      bank_name: null,
      iban: null,
      bic: null,
      invalidIbanError: null,
      files: [],
      hideButton: true,
      industry: null,
    };
  },

  props: ["makeCall", "data", "company"],
  created() {
    this.$http.post("/countries").then((res) => {
      res.data.value.map((item) => {
        this.countries_code.push(item);
        this.nationalities.push({
          value: item.id,
          text: item.name,
        });
        this.nationalities_de.push({
          value: item.id,
          text: item.name_de,
        });
        this.countries.push({
          value: item.iso_code,
          text: `${item.iso_code} (${item.phone_code})`,
        });
      });
      this.getUser();
    });
  },
  watch: {
    makeCall(newValue, oldValue) {
      if (newValue !== oldValue && this.makeCall) {
        this.loader = true;
        this.getUser();

        this.$http.post("/countries").then((res) => {
          res.data.value.map((item) => {
            this.countries.push({
              value: item.id,
              text: item.name,
            });
          });
        });
        this.loader = false;
      }
    },
  },
  computed: {
    disEnable() {
      let disable = false;

      if (this.company && this.company.tasks && this.company.tasks.length > 0) {
        this.company.tasks.forEach((el) => {
          if (el.status != 1) {
            disable = true;
          }
        });
      } else {
        disable = true;
      }
      return disable;
    },
    showBox() {
      let dis = false;

      if (this.company && this.company.tasks && this.company.tasks.length > 0) {
        let company_review_task = this.company.tasks.find(
          (element) => element.name == "Review Buyer Details"
        );

        if (
          typeof company_review_task != "undefined" &&
          company_review_task.status == 0
        ) {
          this.boxTitle = company_review_task.name;
          dis = true;
        }
      }

      return dis;
    },
  },
  methods: {
    uploadIds() {
      if (this.passportFrontFile != null) {
        let formData1 = new FormData();
        this.files.forEach((el) => {
          if (el.documentType == 'passport_front')
            formData1.append('file', el.el);
        });
        let config = { headers: { "Content-Type": "multipart/form-data" } };
        this.$http
          .post(`/buyer/document/passport_front/${this.$route.params.id}`, formData1, config)
          .catch((err) => {
            if (err) {
            }
          })
          .then((res) => {
            if (res.data.status == 200) {
              this.getUser()
            }
          })
      }
      if (this.passportBackFile != null) {
        let formData2 = new FormData();
        this.files.forEach((el) => {
          if (el.documentType == 'passport_back')
            formData2.append('file', el.el);
        });
        let config = { headers: { "Content-Type": "multipart/form-data" } };
        this.$http
          .post(`/buyer/document/passport_back/${this.$route.params.id}`, formData2, config)
          .catch((err) => {
            if (err) {
            }
          })
          .then((res) => {
            if (res.data.status == 200) {
              this.getUser()
            }
          })
      }
    },
    triggerUploadFile(documentType) {
      this.$refs[documentType].$refs.input.click();
    },
    alreadyIncludedInFrontFile() { },
    uploadFile(event, file, documentType, index = null) {
      event.target.files.forEach((el) => {
        this.files.push({ documentType, el });
        if (documentType == "passport_front") {
          this.passportFrontFile = el.name;
          this.hideButton = false;
        }
        if (documentType == "passport_back") {
          this.passportBackFile = el.name;
          this.hideButton = false;
        }

      });
    },
    removeFile(type, file) {

      if (type == "passport_front") {
        this.passportFrontFile = null;
        this.passport_front = null;
      }

      if (type == "passport_back") {
        this.passport_back = null;
        this.passportBackFile = null;
      }

      this.files.forEach((el, index) => {
        if (el.documentType == type) {
          this.files.splice(index, 1);
        }
      });
    },
    changeInvestorStatus() {
      this.displayCompany = !this.displayCompany;

    },
    getPhone(iso, tel) {
      if (this.countries_code && this.countries_code.length > 1) {
        this.countries_code.map((i, item) => {
          if (i.iso_code == iso) {
            tel = tel.replace(i.phone_code, "");
          }
        });
      }
      return tel;
    },
    ageRestriction() {
      var oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 18);
      return oneYearFromNow;
    },
    alertVariantStatus() {
      if (this.company != null) {
        if (this.company.status == 1) {
          return "success";
        } else if (this.company.status == 2) {
          return "danger";
        } else {
          return "warning";
        }
      } else {
        return "secondary";
      }
    },
    checkPhoneFormat() {
      let validation = phone(this.main_contact_tel, {
        country: this.phoneContries,
        validateMobilePrefix: false
      });

      if (validation && validation.isValid == false) {
        this.phoneValidation = `Phone for ${this.phoneContries} must be valid`;
      } else {
        this.phoneValidation = "";
      }
    },
    syncWithSumSub() {
      this.showLoader = true;
      this.$http.post(`/user/sumsub/sync/${this.$route.params.id}`).then((response) => {
        if (response) {
          const res = response.data;
          res.success = res.status === 200;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.success ? "User updated successfully" : res.message,
              icon: res.success ? "SuccessIcon" : "ErrorIcon",
              variant: res.success ? "success" : "error",
            },
          });

          if(res.success) {
            this.$emit("updateData", "Buyer");
          }
        }
      });
    },
    enableCompany(type) {
      this.$http
        .post(`/buyer/admin/update-status-company/${this.$route.params.id}`, {
          type: type,
        })
        .then((res) => {
          if (res) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message
                  ? this.$t(res.data.message)
                  : this.$t("statust_changed_successfully"),
                icon: "SuccessIcon",
                variant: "success",
              },
            });
            this.getUser();
          }
        });
    },
    getUser() {
  this.$http.post(`/buyer/admin/show/${this.$route.params.id}`)
    .then((res) => {
      const userData = res.data.value;
      if (userData) {
        this.status = userData.company.status;
        this.displayCompany = !!userData.company_name;
        this.main_contact_country_id = userData.country_id || "";
        this.main_contact_gender = userData.gender || "";
        this.main_contact_company_name = userData.company_name;
        this.main_contact_first_name = userData.first_name;
        this.main_contact_last_name = userData.last_name;
        this.main_contact_email = userData.email;
        this.main_contact_position = userData.position || null;
        this.main_contact_tel = this.getPhone(userData.iso_code, userData.tel);
        this.phoneContries = userData.iso_code;
        this.main_contact_address = userData.address;
        this.main_contact_address_nr = userData.address_nr;
        if (userData.company) {
          const companyData = userData.company;
          const businessAddress = userData.business_address || {};
          this.companyInfo = {
            ...companyData,
            street_name: companyData.address,
            street_number: companyData.address_nr,
            registration_no: companyData.uid,
            dateOfInc: companyData.established_at,
            country: companyData.country_id,
            businessAddress: {
              street_name: businessAddress.address,
              street_number: businessAddress.address_nr,
              zip: businessAddress.zip,
              city: businessAddress.city,
              country: businessAddress.country_id,
            },
          };
          this.bank_name = companyData.bank_name;
          this.iban = companyData.iban;
          this.bic = companyData.bic;
          this.political_a_1 = userData.answers.political_a_1;
          if (userData.answers.political_a_1 && userData.answers.political_a_1 == 1) {
            this.pa1_role = userData.answers.pa1_role;
            this.pa1_country = userData.answers.pa1_country;
          }
          this.political_a_2 = userData.answers.political_a_2;
          if (userData.answers.political_a_1 && userData.answers.political_a_2 == 1) {
            this.pa2_role = userData.answers.pa2_role;
            this.pa2_country = userData.answers.pa2_country;
            this.pa2_first_name = userData.answers.pa2_first_name;
            this.pa2_last_name = userData.answers.pa2_last_name;
          }
          if (businessAddress && businessAddress.business_address_value == 1) {
            this.bbAdVl = businessAddress.business_address_value;
            this.companyInfo.businessAddress_street_name = businessAddress.address;
            this.companyInfo.businessAddress_street_number = businessAddress.address_nr;
            this.companyInfo.businessAddress_zip = businessAddress.zip;
            this.companyInfo.businessAddress_city = businessAddress.city;
            this.companyInfo.businessAddress_country = businessAddress.country_id;
          }
          this.companyInfo.tax_residence = businessAddress.tax_residence;
          this.companyInfo.tin = businessAddress.tin;
          this.companyInfo.vat = businessAddress.vat;
          this.companyInfo.license = businessAddress.license;
          this.companyInfo.usTaxPayer = businessAddress.usTaxPayer;
          this.companyInfo.companyEntitled = businessAddress.companyEntitled;
        }
        
        this.industry = userData.company.industry;
        this.passportFrontFile = userData.identifiaction_documents?.passport_front || null;
        this.passportBackFile = userData.identifiaction_documents?.passport_back || null;
        this.main_contact_birthday = moment(userData.birthday, "DD.MM.YYYY").format("YYYY-MM-DD");
        this.main_contact_nationality = userData.nationality_id || "";
        this.main_contact_industry = userData.company.industry;
        this.main_contact_zip = userData.zip;
        this.main_contact_city = userData.city;
        this.terms_and_conditions = true;
        this.mapAnswersToQuestions(this.financial_questions, userData.answers, "financial_answer_");
        this.mapAnswersToQuestions(this.qualification_questions, userData.answers, "qualification_answer_");
      }
    });
},
mapAnswersToQuestions(questionsArray, answers, answerPrefix) {
  questionsArray.forEach((item) => {
    const answerKey = `${answerPrefix}${item.id}`;
    item.answer = answers ? answers[answerKey] : null;
  });
},
    updateCompanyStatus(status) {
      this.$http
        .post(
          `/company/admin/update/status/buyer/${this.company.id}/${status}`,
          {
            reason: this.reason,
          }
        )
        .then((res) => {
          this.$emit("updateData", "Buyer");
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t(res.data.message),
              icon: "SuccessIcon",
              variant: "success",
            },
          });
        });
    },
    saveBuyerInfo() {
      this.$refs.buyerRegisterValidation.validate().then((success) => {
        if (success && this.phoneValidation.length < 1) {
          this.disableInputs = true;
          const formSubmissionData = {
            gender: this.main_contact_gender,
            first_name: this.main_contact_first_name,
            last_name: this.main_contact_last_name,
            company_name: this.main_contact_company_name,
            position: this.main_contact_position,
            tel: this.main_contact_tel,
            address: this.main_contact_address,
            address_nr: this.main_contact_address_nr,
            birthday: this.main_contact_birthday,
            nationality: this.main_contact_nationality,
            country_id: this.main_contact_country_id,
            industry: this.main_contact_industry,
            zip: this.main_contact_zip,
            city: this.main_contact_city,
            iso_code: this.phoneContries,
            passport_front: this.passport_front,
            passport_back: this.passport_back,
            file_passport_back_included: this.file_passport_back_included,
            political_a_1: this.political_a_1,
            political_a_2: this.political_a_2,
            pa1_role: this.pa1_role ? this.pa1_role : null,
            pa1_country: this.pa1_country ? this.pa1_country : null,
            pa2_country: this.pa2_country ? this.pa2_country : null,
            pa2_role: this.pa2_role ? this.pa2_role : null,
            pa2_first_name: this.pa2_first_name ? this.pa2_first_name : null,
            pa2_last_name: this.pa2_last_name ? this.pa2_last_name : null,
            companyInfo: this.companyInfo ? {
              legal_form: this.companyInfo.legal_form,
              registration_no: this.companyInfo.registration_no,
              street_name: this.companyInfo.street_name,
              street_number: this.companyInfo.street_number,
              zip: this.companyInfo.zip,
              city: this.companyInfo.city,
              country: this.companyInfo.country,
              website: this.companyInfo.website ? this.companyInfo.website : null,
              business_address_value: this.bbAdVl,
              businessAddress: {
                street_name: this.companyInfo.businessAddress_street_name,
                street_number: this.companyInfo.businessAddress_street_number,
                zip: this.companyInfo.businessAddress_zip,
                city: this.companyInfo.businessAddress_city,
                country: this.companyInfo.businessAddress_country
              },
              industry: this.companyInfo.businessActivity,
              license: this.companyInfo.license,
              dateOfInc: this.companyInfo.dateOfInc,
              tax_residence: this.companyInfo.tax_residence,
              vat: this.companyInfo.vat,
              tin: this.companyInfo.tin,
              companyEntitled: this.companyInfo.companyEntitled,
              usTaxPayer: this.companyInfo.usTaxPayer,
            } : null,
            bank_name: this.bank_name,
            iban: this.iban,
            bic: this.bic,
          };
          let data = {};
          this.financial_questions.map((item) => {
            const financial_question = {
              ["financial_answer_" + item.id]: item.answer,
            };
            data = { ...data, ...financial_question };
          });
          this.qualification_questions.map((item) => {
            const qualification_question = {
              ["qualification_answer_" + item.id]: item.answer,
            };
            data = { ...data, ...qualification_question };
          });
          data = { ...data, ...formSubmissionData };
          this.$http
            .post("/buyer/admin/update/" + this.$route.params.id, data)
            .then((res) => {
              let mess = "";
              let icon = "";
              let variant = "";
              if (res && res.data.status == 500) {
                mess = res.data.message;
                icon = "ErrorIcon";
                variant = "error";
              } else {
                mess = "buyer_updated_successfully";
                icon = "SuccessIcon";
                variant = "success";
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t(mess),
                  icon: icon,
                  variant: variant,
                },
              });
              this.getUser();
            });
        } else {
          const el = document.getElementsByClassName("is-invalid");
          if (el) {
            setTimeout(function () {
              el[0].scrollIntoView({ behavior: "smooth", block: "center" });
            }, 500);
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Some fields are missing",
              icon: "ErrorIcon",
              variant: "danger",
            },
          });
        }
        this.disableInputs = false;
      });
    },
    notActiveNow() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("not_yet_active_for_companies"),
          icon: "ErrorIcon",
          variant: "error",
        },
      });
    },
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      this.country_id = null;
    },
  },
};
</script>
<style scoped>
.uploaded-text {
  padding: 10px 30px;
  background-color: #55dd92;
  border-radius: 5px;
  color: white;
}
</style>
