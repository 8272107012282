<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <b-row v-if="task != null" class="col-md-12 mt-2">
        <b-col cols="12 mb-1">
          <b-badge pill variant="light-secondary" v-if="task.status == 0">{{
            $t("pending")
          }}</b-badge>
          <b-badge pill variant="light-success" v-if="task.status == 1">{{
            $t("approved")
          }}</b-badge>
          <b-badge pill variant="light-danger" v-if="task.status == 2">{{
            $t("declined")
          }}</b-badge>
          <b-badge pill variant="light-success" v-if="task.status == 3">{{
            $t("completed")
          }}</b-badge>
          <b-badge pill variant="light-warning" v-if="task.status == 4">{{
            $t("partially_approved")
          }}</b-badge>
        </b-col>
        <b-col class="col-md-12">
          <h3 class="light-blue">{{ task.name }}</h3>
        </b-col>
        <hr class="col-md-12" />
        <div v-if="task.status != 3" class="col-md-12 row">
          <b-col class="col-md-6 mt-1 mb-1">
            <h6 v-if="task.status != 1">{{ $t("task_needs_approval") }}</h6>
            <b-button
              variant="info "
              v-if="task.status != 1"
              @click="
                $emit('approveModalTask', { name: 'approve', id: task.id })
              "
              >{{ $t("approve") }}</b-button
            >

            <b-button
              variant="info "
              v-else
              @click="
                $emit('approveModalTask', { name: 'decline', id: task.id })
              "
              >{{ $t("resset_approval") }}</b-button
            >
          </b-col>
          <b-col class="col-md-6 mt-1 mb-1" aling="end">
            <h6 v-if="task.status != 1" class="mt-2"></h6>
            <b-button
              variant="primary"
              @click="
                $emit('approveModalTask', { name: 'complete', id: task.id })
              "
              >{{ $t("complete_task") }}</b-button
            >
          </b-col>

          <hr class="col-md-12" />
        </div>

        <app-collapse class="col-md-12">
          <app-collapse-item :title="$t('comments')">
            <b-card>
              <label for="comment">{{ $t("comments") }}</label>
              <b-form-textarea
                id="comment"
                placeholder="Comment"
                rows="3"
                v-model="commentText"
              />

              <div class="text-right pt-1">
                <b-button variant="primary" @click="addComment()">{{
                  $t("comment")
                }}</b-button>
              </div>
              <app-timeline>
                <app-timeline-item
                  v-for="(comment, index) in task.comments"
                  :key="index"
                >
                  <div
                    class="
                      d-flex
                      flex-sm-row flex-column flex-wrap
                      justify-content-between
                      mb-1 mb-sm-0
                    "
                  >
                    <h6>
                      {{ comment.user.name + " " + comment.user.last_name }}
                    </h6>
                    <small class="text-muted">{{
                      comment.created_at | formatDate
                    }}</small>
                  </div>
                  <p>{{ comment.comment }}</p>
                </app-timeline-item>
              </app-timeline>
            </b-card>
          </app-collapse-item>
          <app-collapse-item :title="$t('Attachments')">
            <b-card>
              <app-timeline>
                <app-timeline-item
                  v-for="(attachment, index) in task.attachments"
                  variant="warning"
                  :key="index"
                >
                  <div
                    class="
                      d-flex
                      flex-sm-row flex-column flex-wrap
                      justify-content-between
                      mb-1 mb-sm-0
                    "
                  >
                    <h6>
                      {{
                        attachment.user.name + " " + attachment.user.last_name
                      }}
                    </h6>
                    <small class="text-muted">{{
                      attachment.created_at | formatDate
                    }}</small>
                  </div>
                  <p @click="downloadPdf(attachment.id)">
                    <b-img
                      :src="require('@/assets/images/icons/pdf.png')"
                      height="auto"
                      width="20"
                      class="mr-1"
                    />
                    <span class="align-bottom">{{ attachment.file }}</span>
                  </p>
                </app-timeline-item>
                <b-form-file
                  v-model="file"
                  @change="uploadFile($event)"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                ></b-form-file>
              </app-timeline>
            </b-card>
          </app-collapse-item>
          <app-collapse-item :title="$t('Aapprovals')">
            <b-card>
              <app-timeline>
                <app-timeline-item
                  v-for="(approval, index) in task.approves"
                  :key="index"
                  :variant="approval.status == 1 ? 'success' : 'danger'"
                >
                  <div
                    class="
                      d-flex
                      flex-sm-row flex-column flex-wrap
                      justify-content-between
                      mb-1 mb-sm-0
                    "
                  >
                    <h6>
                      {{ approval.user.name + " " + approval.user.last_name }}
                    </h6>
                    <small class="text-muted">{{
                      approval.created_at | formatDate
                    }}</small>
                  </div>
                  <p>
                    {{ $t("changed_status_to") }}
                    <span class="text-success" v-if="approval.status == 1">{{
                      $t("approved")
                    }}</span>
                    <span class="text-danger" v-if="approval.status == 2">{{
                      $t("declined")
                    }}</span>
                  </p>
                </app-timeline-item>
              </app-timeline>
            </b-card>
          </app-collapse-item>
        </app-collapse>
      </b-row>
    </b-sidebar>
  </div>
</template>
<script>
import {
  BImg,
  BAvatar,
  BMedia,
  BButton,
  BCollapse,
  VBToggle,
  BListGroup,
  BListGroupItem,
  BAvatarGroup,
  BBadge,
  VBTooltip,
  BRow,
  BCol,
  BFormTextarea,
  BFormFile,
  BCard,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import fileDownload from "js-file-download";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url } from "@validations";
import { avatarText } from "@core/utils/filter";
import formValidation from "@core/comp-functions/forms/form-validation";
import { toRefs } from "@vue/composition-api";
import { quillEditor } from "vue-quill-editor";
import useTaskHandler from "./useTaskHandler";
export default {
  name: "Task",
  components: {
    AppTimeline,
    AppTimelineItem,
    AppCollapse,
    AppCollapseItem,
    BImg,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BButton,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    BBadge,
    BFormTextarea,
    BFormFile,
    BCard,
    vSelect,
    quillEditor,
    useTaskHandler,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: { "b-toggle": VBToggle, "b-tooltip": VBTooltip, Ripple },
  props: ["task_id", "isTaskHandlerSidebarActive", "task", "clearTaskData"],

  data() {
    return {
      isLoading: false,
      commentText: null,
      file: [],
      required: false,
      email: "",
      url: "",
      modalText: null,
      modalTitle: null,
      showInput: null,
      showDialog: false,
    };
  },
  created() {
    this.getTask();
  },
  model: {
    prop: "isTaskHandlerSidebarActive",
    event: "update:is-task-handler-sidebar-active",
  },
  methods: {
    clearForm() {},
    downloadPdf(id) {
      this.isLoading = true;
      this.$http
        .post(
          "/task/admin/download/" + id,
          {},
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          fileDownload(response.data, "doc.pdf");
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    uploadFile(event) {
      let formData = new FormData();
      formData.append("pdf", event.target.files[0]);
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.$http
        .post("/task/admin/addAttachment/" + this.task.id, formData, config)
        .then((response) => {
          this.file = [];
          this.task = response.data.value;
        })
        .catch((error) => {});
    },
    getTask() {
      this.$http
        .post("/task/admin/show/" + this.task_id, { comment: this.commentText })
        .then((response) => {
          this.task = response.data.value;
        })
        .catch((error) => {});
    },
    addComment() {
      this.$http
        .post("/task/admin/addComment/" + this.task.id, {
          comment: this.commentText,
        })
        .then((response) => {
          this.task = response.data.value;
          this.commentText = null;
          this.$emit("changeInTaskDetails");
        })
        .catch((error) => {});
    },
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
